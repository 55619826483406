import { graphql } from 'gatsby';
import React from 'react';
import Heimdall from '../src/components/Heimdall/Heimdall';
import { Odin } from '../src/components/Odin/Odin';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import Uthred from '../src/components/Uthred/Uthred';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import Hod from '../src/components/Hod/Hod';
import tt from '../src/helpers/translation';
import Layout from '../src/components/Layout/Layout';
import Icon from '../src/helpers/icon';
import materialGrafico from '../src/icons/material-grafico';
import SEO from '../src/helpers/seo';

import './material_grafico_navidad.scss';

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query materialNavidadQuery($locale: String!) {
    allMaterialNavidadSeoBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allMaterialNavidadTitle(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allMaterialNavidadImageHeaderBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allMaterialNavidadBreadcrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allMaterialNavidadOdin(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          ctaText
          cta
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allMaterialNavidadImageList(filter: { lang: { eq: "es" } }) {
      edges {
        node {
          imageArr
        }
      }
    }
    allMaterialNavidadPopUp(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          body
          ctaText
        }
      }
    }
    allMaterialNavidadRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
          }
        }
      }
    }
  }
`;

class MaterialNavidad extends React.Component {
  state = {};

  retrieveImageList = (array) =>
    array.map((e) => (
      <div className="ma-photo">
        <img src={e} alt="img" />
        <div className="photo-icon">
          <a href={e} download>
            <Icon
              icon="get_app"
              width="30"
              height="30"
              class="download-icon"
              iconSet={materialGrafico}
            />
          </a>
        </div>
      </div>
    ));

  render() {
    const heimdallData = {
      name: this.props.data.allMaterialNavidadTitle.edges[0].node.title,
      image: {
        url: this.props.data.allMaterialNavidadImageHeaderBlock.edges[0].node.localImage
          .childImageSharp.fluid,
      },
    };

    const odinData = {
      image: this.props.data.allMaterialNavidadImageList.edges[0].node.imageArr[0],
      alt: 'GALERIA NAVIDAD',
      title: tt(
        'La Navidad de PortAventura Park te espera con la mejor tematización. ¿Te lo vas a perder?',
        this.props.pageContext.locale
      ).toUpperCase(),
      rssImg: true,
      buttons: {
        cta1: this.props.data.allMaterialNavidadOdin.edges[0].node.cta,
        ctaText1: this.props.data.allMaterialNavidadOdin.edges[0].node.ctaText,
        size: 'alone',
        color1: 'orange',
      },
    };
    const h2Data = {
      title: this.props.data.allMaterialNavidadTitle.edges[0].node.description,
    };

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allMaterialNavidadSeoBlock.edges[0].node._0.title}
          description={this.props.data.allMaterialNavidadSeoBlock.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <Uthred data={this.props.data.allMaterialNavidadPopUp.edges[0].node} />
          <div className="general-index">
            <div className="graphic-container">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allMaterialNavidadBreadcrumbBlock.edges[0].node.name}
                options={this.props.pageContext.options}
              />
              <Hod
                data={this.props.data.allMaterialNavidadRichData.edges[0].node.rich_markup}
                url={this.props.pageContext.url}
                img={
                  this.props.data.allMaterialNavidadImageHeaderBlock.edges[0].node.localImage
                    .childImageSharp.fluid
                }
              />
              <H2 data={h2Data} />
              <Odin data={odinData} locale={this.props.pageContext.locale} />
              <div className="gallery-photos">
                {this.retrieveImageList(
                  this.props.data.allMaterialNavidadImageList.edges[0].node.imageArr
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default MaterialNavidad;
